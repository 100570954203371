.autocompleteList {
    padding: 0px;
    margin: 0px;
    display: none;
    max-height: 100px;
    background: rgb(255, 255, 255);
    overflow: auto;
    border: 1px solid rgb(171, 171, 171);
    position: absolute;
    width: calc(100% - 60px);
    box-sizing: border-box;
    z-index: 10;
}

.autocompleteList li {
    padding: 0px;
    margin: 0px;
    font-size: 0.8em;
    padding: 0.2em;
    border-bottom: 1px solid rgb(171, 171, 171);
}

.autocompleteList li:hover {
    cursor: pointer;
    background: rgb(207, 207, 207);
}

.autocompleteInput:focus + .autocompleteList,
.autocompleteList:hover {
    display: block;
}

.btn-custom {
    padding: 0px 5px;
    margin: 0px 5px;
}

.btn-custom:hover {
    background: rgba(0, 0, 0, 0.4) !important;
    color: rgb(255, 255, 255) !important;
}
