.mounth-grid {
    display: grid;
  }
  

  .mounth-grid.has-3 {
    grid-template-columns: auto auto auto;
  }

  .mounth-grid.has-4 {
    grid-template-columns: auto auto auto auto;
  }