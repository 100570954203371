.custom-collapse {
  border: 1px solid rgba(0, 0, 0, 0.375);
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
}

.custom-collapse.active {
  background: rgb(255, 212, 212);
}

.custom-collapse.deadline {
  background: rgb(212, 255, 224);
}

.custom-collapse-header {
  font-size: 1.1em;
  padding: 5px 10px;
  font-weight: bolder;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  text-transform: capitalize;
}
.custom-collapse-header:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-collapse-body {
  border-top: 1px solid rgba(0, 0, 0, 0.375);
  padding: 5px 10px;
  transition: width 2s, height 4s, display 6s;
}

.table {
  margin: 0px;
}

thead {
  font-weight: bold;
}

.prix-row button {
  display: none;
}

.prix-row:hover button {
  display: inline;
}

.card-header h2 {
  margin: 0px;
}
